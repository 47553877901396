import { Line } from 'react-chartjs-2';

const ChartView = ({ chartData, onBackToChat }) => {
  // Prepare data for the chart
  const dates = chartData.map((point) => point.date).reverse();
  const prices = chartData.map((point) => point.close).reverse();

  const data = {
    labels: dates,
    datasets: [
      {
        label: 'Closing Price',
        data: prices,
        borderColor: '#4caf50',
        backgroundColor: 'rgba(76, 175, 80, 0.2)',
      },
    ],
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        <Line data={data} />
      </div>
      <div style={{ flex: 1 }}>
        <button onClick={onBackToChat}>Back to Chat</button>
      </div>
    </div>
  );
};

export default ChartView;
