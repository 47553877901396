import { useState, useContext } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import { AuthContext } from './context/AuthContext';
import ChatView from './components/ChatView';
import ChartView from './components/ChartView';
import LoginView from './components/LoginView';
import apiClient from './api/apiClient'; // Ensure correct import path

const App = () => {
  const { auth } = useContext(AuthContext);
  const [view, setView] = useState('chat'); // 'chat' or 'chart'
  const [chartData, setChartData] = useState([]);

  const loadChart = async (ticker) => {
    const response = await apiClient.get(`/chart/${ticker}`);
    setChartData(response.data.chart_data);
    setView('chart');
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={!auth.token ? <LoginView /> : <Navigate to="/" />}
        />
        <Route
          path="/"
          element={
            auth.token ? (
              view === 'chat' ? (
                <ChatView onLoadChart={loadChart} />
              ) : (
                <ChartView
                  chartData={chartData}
                  onBackToChat={() => setView('chat')}
                />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
