import { useState, useRef, useEffect } from 'react';
import './ChatView.css';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import Header from './Header';
import Sidebar from './Sidebar';
import apiClient from '../api/apiClient';
import { ReactComponent as UserIcon } from '../assets/icons/userIcon.svg';
import { ReactComponent as LuteceIcon } from '../assets/icons/luteceIcon.svg';
import { ReactComponent as SendArrowIcon } from '../assets/icons/sendArrowIcon.svg';

const ChatView = ({ onLoadChart }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [currentChatId, setCurrentChatId] = useState(null);
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);

  // Fetch chats on component mount
  useEffect(() => {
    fetchChats();
  }, []);

  const fetchChats = async () => {
    const response = await apiClient.get('/chat/all/');
    setChats(response.data);
  };

  const handleChatSelect = async (chat) => {
    if (chat) {
      const response = await apiClient.get(`/chat/${chat.id}/`);
      setCurrentChatId(chat.id);
      const formattedMessages = response.data.messages.map((msg) => ({
        sender: msg.is_bot ? 'bot' : 'user',
        text: msg.content,
      }));
      setMessages(formattedMessages);
    } else {
      // Create new chat
      const response = await apiClient.post('/chat/new/');
      setCurrentChatId(response.data.id);
      setMessages([]);
      await fetchChats(); // Refresh chat list
    }
  };

  const sendMessage = async () => {
    let chatId = currentChatId;

    if (!chatId) {
      const chatResponse = await apiClient.post('/chat/new/');
      chatId = chatResponse.data.id;
      setCurrentChatId(chatId);
    }

    const trimmedInput = input.trim();
    if (trimmedInput === '') return;

    // Create a user message object
    const userMessage = { sender: 'user', text: trimmedInput };

    // Add the user message to the chat immediately
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput('');
    setIsLoading(true);

    const botMessage = { sender: 'bot', text: '' };
    setMessages((prevMessages) => [...prevMessages, botMessage]);

    // `messages.length` is one step behind due to React's state updates
    // we need to target the bot message that was just added, not the user message
    const botMessageId = messages.length + 1;

    try {
      const baseUrl =
        process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';
      const token = localStorage.getItem('access_token');

      const response = await fetch(`${baseUrl}/chat/${chatId}/message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ query: trimmedInput }),
      });

      if (!response.ok) {
        if (response.status === 401) {
          // Show alert before redirecting
          alert('Your session has expired. Please log in again.');
          localStorage.removeItem('access_token');
          window.location.reload();
          return;
        }
        throw new Error(`Server error: ${response.statusText}`);
      }

      if (!response.body) {
        throw new Error('ReadableStream not supported in this browser.');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let botMessageContent = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        const currentContent = botMessageContent + chunk;
        botMessageContent = currentContent;

        setMessages((prevMessages) => {
          const newMessages = [...prevMessages];
          if (newMessages[botMessageId]) {
            newMessages[botMessageId].text = currentContent;
          }
          return newMessages;
        });
      }

      // Handle stock ticker detection
      const match = trimmedInput.match(/how's\s+([A-Z]+)\s+doing/i);
      if (match) {
        const ticker = match[1];
        onLoadChart(ticker);
      }

      await fetchChats(); // Refresh chat list after message is sent
      textareaRef.current.focus();
    } catch (error) {
      alert(
        'There was an error communicating with the server. Please try again later.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const autoResizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Reset height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set to scrollHeight
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // useEffect to handle auto-resizing when 'input' changes
  useEffect(() => {
    autoResizeTextarea();
  }, [input]);

  // useEffect to scroll to the latest message when 'messages' change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="app-container">
      <Header />
      <div className="main-content">
        <Sidebar
          chats={chats}
          onChatSelect={handleChatSelect}
          currentChatId={currentChatId}
          setChats={setChats}
        />
        <div className="chat-container">
          <div className="chat-box">
            {messages.map((msg, idx) => (
              <div key={idx} className={`message ${msg.sender}`}>
                <div className="icon">
                  {msg.sender === 'user' ? <UserIcon /> : <LuteceIcon />}
                </div>
                <div className="message-content">
                  {msg.sender === 'user' ? (
                    <div className="user-bubble">{msg.text}</div>
                  ) : msg.text ? (
                    <ReactMarkdown
                      className="markdown-content"
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                      components={{
                        table: ({ node, ...props }) => (
                          <div style={{ overflowX: 'auto', width: '100%' }}>
                            <table {...props} style={{ minWidth: '100%' }} />
                          </div>
                        ),
                        td: ({ node, ...props }) => (
                          <td
                            {...props}
                            style={{ border: '1px solid #ddd', padding: '8px' }}
                          />
                        ),
                        th: ({ node, ...props }) => (
                          <th
                            {...props}
                            style={{
                              border: '1px solid #ddd',
                              padding: '8px',
                              backgroundColor: '#f5f5f5',
                            }}
                          />
                        ),
                      }}
                    >
                      {msg.text}
                    </ReactMarkdown>
                  ) : isLoading && idx === messages.length - 1 ? (
                    <span>Typing...</span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className="input-container">
            <div className="input-wrapper">
              <textarea
                ref={textareaRef}
                className="chat-input"
                value={input}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder="Ask Lutece anything..."
                rows={1}
                aria-label="Chat input"
              />
              <button
                onClick={sendMessage}
                className="send-button"
                aria-label="Send message"
              >
                <SendArrowIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatView;
