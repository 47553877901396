import { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    token: localStorage.getItem('access_token'),
    user: null,
  });

  useEffect(() => {
    if (auth.token) {
      try {
        const decoded = jwtDecode(auth.token);
        if (decoded.exp * 1000 < Date.now()) {
          localStorage.removeItem('access_token');
          setAuth({ token: null, user: null });
        } else {
          setAuth((prev) => ({ ...prev, user: decoded.sub }));
        }
      } catch (error) {
        localStorage.removeItem('access_token');
        setAuth({ token: null, user: null });
      }
    }
  }, [auth.token]);

  const login = (token) => {
    localStorage.setItem('access_token', token);
    setAuth({ token, user: jwtDecode(token).sub });
  };

  const logout = () => {
    localStorage.removeItem('access_token');
    setAuth({ token: null, user: null });
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
