import './Sidebar.css';
import { ReactComponent as NewChatIcon } from '../assets/icons/newChatIcon.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/closeIcon.svg';
import apiClient from '../api/apiClient';

const Sidebar = ({ chats, onChatSelect, currentChatId, setChats }) => {
  const handleDeleteChat = async (e, chatId) => {
    e.stopPropagation(); // Prevent chat selection when clicking delete
    await apiClient.delete(`/chat/${chatId}`);
    // Refresh the page to update chat list
    setChats((prevChats) => prevChats.filter((chat) => chat.id !== chatId));
  };

  return (
    <div className="sidebar">
      <div className="new-chat-button-container">
        <button onClick={() => onChatSelect(null)} className="new-chat-button">
          <NewChatIcon />
          <span>New Chat</span>
        </button>
      </div>
      <div className="chats-list">
        {chats.map((chat) => (
          <div
            key={chat.id}
            className={`chat-item ${chat.id === currentChatId ? 'active' : ''}`}
            onClick={() => onChatSelect(chat)}
          >
            <span className="chat-title">{chat.title}</span>
            <button
              className="delete-chat-button"
              onClick={(e) => handleDeleteChat(e, chat.id)}
              aria-label="Delete chat"
            >
              <CloseIcon />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
