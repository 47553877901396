import { useState, useContext } from 'react';
import apiClient from '../api/apiClient';
import { AuthContext } from '../context/AuthContext';
import './LoginView.css'; // Create a CSS file similar to ChatView.css

const LoginView = () => {
  const { login } = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async () => {
    try {
      const response = await apiClient.post('/auth/login/', {
        username,
        password,
      });
      login(response.data.access_token);
    } catch (err) {
      setError('Invalid username or password');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div className="login-container">
      <div className="login-header">
        <h1>Login to LTCEast</h1>
      </div>
      <div className="login-box">
        {error && <div className="error-message">{error}</div>}
        <input
          type="text"
          className="login-input"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          onKeyPress={handleKeyPress}
          aria-label="Username"
        />
        <input
          type="password"
          className="login-input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          onKeyPress={handleKeyPress}
          aria-label="Password"
        />
        <button
          onClick={handleLogin}
          className="login-button"
          aria-label="Login"
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default LoginView;
